import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux';
import SEO from '../components/seo'
import {Link} from 'gatsby';
import FacebookButton from '../components/buttons/facebook';
import TwitterButton from '../components/buttons/twitter';
import InstagramButton from '../components/buttons/instagram';
import classNames from 'classnames';
import { tablet, desktop } from '../breakpoints';
import ReactPlayer from 'react-player'
import Studio from '../video/Studio.mp4';
import intro1 from '../images/live-intro1.jpg';
import intro2 from '../images/live-intro2.jpg';
import intro3 from '../images/live-intro3.jpg';
import poster from '../images/videoposter.jpg';

class LivePage extends Component {

	constructor(){
		super();
        this.state = {
            videoPlaying: false
        }
	}

	componentDidMount(){

	

	}

	
	componentWillUnmount(){
	
	}

	/*
    |--------------------------------------------------------------------------
    | watch for prop updates
    |--------------------------------------------------------------------------
    */
   	componentWillReceiveProps(next){
		
	}

	render() { 

	
		return (
			<Page>

				<SEO title="Live" keywords={[`radio`, `award winning`, `production`]} />

				<Wrapper>
					<Title>Live Broadcasts</Title>
					
                    <VideoContainer onClick={()=>{ this.setState({videoPlaying: !this.state.videoPlaying}) }}>                  
                        <div className="inner"><ReactPlayer loop playing={this.state.videoPlaying} fileConfig={{ attributes: { poster: poster } }} url={Studio} width="100%" height="auto"  /></div>
                    </VideoContainer>
				</Wrapper>



                <LiveInfo>
					

					<Inner>
                        
                        <div className="intro inner">
                            <p>Some of the most engaging, compelling audio content can be created when outside broadcasting from live events. </p>
							<p>We have 20 years’ experience in taking our world-class studio set ups on location from Buenos Aires to Sydney, Kuala Lumpur to Amsterdam.   </p>
							<p>We’re a key fixture at Ultra Music Festival, and we’ve produced countless shows direct from Miami’s Music Week.  </p>
							<p>We’ve also provided sponsored studios for brands including Relentless, and Rimmel London at Isle of MTV.</p>
						</div>
						
                        <Container>
							<Left>
								<IntroPics>
                                    <img src={intro1} />
                                    <img src={intro2} />
                                </IntroPics>
							</Left>
							<Right >
								<Divider/>
								<Subtitle>What We Do</Subtitle>
                                <p>We build a complete studio set-up, delivered to your specific requirements, anywhere in the world. </p>
								<p>From there our team of in-house producers and audio engineers can feed sound, live or pre-recorded, from as many additional sources as you need across multiple stages, outside areas, two-way interview set-ups and even acoustic performances. </p>
								<p>We can also produce and distribute on-location vox pops, podcasts, audio clips, news reports and interviews. </p>
							</Right>
						</Container>

                        <ContainerSecond>
							<Left>
                                 <Divider/>
                                <Subtitle>Why Choose Us?</Subtitle>
                                <p>We consult on every step of the process – from pitching the concept to creating a full package of exclusive jingles, idents, voice-overs, packages, show openers and closers, while also delivering graphic imagery, marketing materials, handling social assets, interactions and uploads.  </p>
								<p>And on-the-ground we are self-contained and low maintenance.</p>
								<p>Every aspect of the production is managed to the highest possible standard.</p>
							</Left>
							<Right >
								<IntroPics>
                                    <img src={intro3} />
                                </IntroPics>
							</Right>
						</ContainerSecond>

					</Inner>
					
				</LiveInfo>
				
                <CallToAction>
                    <div className="inner">
                        <button><a href="mailto:gavin@theradiodepartment.com">For more information please click here</a></button>
                        <button><a href="http://theradiodepartment.com/files/demos/liveshowcase.mp3 " target="_blank">Click here for an audio sample of live broadcasts</a></button>
                    </div>
                </CallToAction>
			</Page>
		)

  }

}

function mapStateToProps(state){
	return { 
	
	}
}

export default connect(mapStateToProps, {  })(LivePage);


/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Page = styled.section`
	min-height:100%;
`

const Wrapper = styled.section`
	padding-top:100px;
	background:#c2b0bc;
`

const CallToAction = styled.section`
    background:#c2b0bc;
    padding:70px 40px;

    .inner {
        display:flex;
        max-width:1100px;
        margin:auto;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: ${tablet}) {
            display:block;
        }

        button {
            border:1px solid #000;
            border-radius:30px;
            background:transparent;
            padding:9px;
            font-size:16px;
            margin-right: 15px;
            outline:none;
			width:45%;
			
			a {
				text-decoration:none;
				color:#000;
			}

			

            &:hover {
                background: #000;
                color:#f5ede2;
				cursor:pointer;
				
				a {
					color:#f5ede2;
				}
            }

            @media only screen and (max-width: ${tablet}) {
                padding: 9px;
                width:100%;
                font-size: 12px;
                margin-right: 0;
                margin-bottom:20px;

                &:last-child {
                    margin-bottom:0;
                }
            }
        }
    }
`

const LiveInfo = styled.section`
	min-height:100%;
	padding-top:40px;
    background:#f0e452;
    color:#000;
    font-size:22px;
	line-height:1.6em;	

    .inner {
        padding:0 40px 40px 40px;
    }

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		font-size:18px;
	 }	 
`

const VideoContainer = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: auto;
    position: relative;
    //padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    //height: 0;
    cursor:pointer;

    .inner {
        padding:0 40px 40px 40px;
    }
`

const Title = styled.h1`
	font-size: 7vw;
	line-height:1em;
    letter-spacing:0.01em;
	margin:0;
	margin-bottom:30px;
	font-weight:normal;
	text-align:center;

	@media only screen and (max-width: ${tablet}) {
		font-size:40px;
		padding:0 40px;
		margin-bottom:0;
	 }

	 @media only screen and (min-width: ${desktop}) {
		font-size:100px;
	 }
`

const Container = styled.section`
    padding:20px;
    padding-top:0;
    padding-bottom:20px;
	min-height:100%;
	display:flex;

	@media only screen and (max-width: ${tablet}) {
		flex-flow: column-reverse;
		padding-bottom:0;
	 }
`

const ContainerSecond = styled.section`
    padding:20px;
    padding-top:0;
    padding-bottom:20px;
	min-height:100%;
	display:flex;

	@media only screen and (max-width: ${tablet}) {
		flex-flow: column;
		padding-bottom:0;
     }
     
    
`

const IntroPics = styled.div`
     padding-top:10px;

     img {
         max-width:100%;
         margin-bottom:20px;
     }

     img:last-child {
         margin-bottom:0;
     }
`

const Inner = styled.div`
	max-width:1100px;
	width:100%;
    margin:auto;

    .intro {
        p {
            font-size: 30px;
            line-height: 1.5em;

            @media only screen and (max-width: ${tablet}) {
                font-size:25px;
             }	
        }
    }
`

const Left = styled(Container)`
	margin-right: auto;
	width:50%;
	padding-right:5%;
    position:relative;
    display:block;

	@media only screen and (max-width: ${tablet}) {
		width:100%;
        padding-bottom:40px;
	 }

	 .imageWrapper {
		overflow: hidden;
		position: absolute;
		top: 20px;
		left: 20px;
		right: 0;
		bottom: 0;
		width: 90%;
		height: 100%;
		z-index:100;
		transition: 1.2s ease;
	 }

	 .imageWrapper.outToLeft {
		 width: 0%;
		 height:100% !important;
		 transition: width 1.2s ease;
	 }

	 .imageWrapper.outFromBottom {
		height: 0%;
		transition: height 1.2s ease;
	}

	 .gatsby-image-wrapper {
		width: 100%;
		margin-top:10px;

		@media only screen and (max-width: ${tablet}) {
			width:100% !important;
			
		 }

		img {
			height:auto !important;
			max-width:411px;

			@media only screen and (max-width: ${tablet}) {
				
				width:350px !important;
			 }
		}
	}
`

const Subtitle = styled.h2`
    font-family:'EuclidFlex';
    font-weight:normal;
    font-size: 28px;
`

const Right = styled(Container)`
	margin-left: auto;
	display:block;
	width:50%;
	color:#000;
    font-size:22px;
    line-height:1.6em;	

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		font-size:18px;
	 }	 

	ul.meta {
		display:flex;
		flex-direction: row;
		align-items: center;
		padding:0;
		justify-content: flex-start;
		margin-top:40px;

		li {
			list-style:none;
			margin:0;
			padding:0;
			display:inline-block;

			svg {
				width:16px;
				height:16px;
				fill:#000;
			}
		}

		li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6), li:nth-child(7), li:nth-child(8)  {
			button {
				border-radius:50%;
				border:1px solid #000;
				width:35px;
				height:35px;
				text-align:center;
			}
		}

		li.button-stop, li.button-pause, li.button-play, li.button-playdemo, li.button-play {
			display:none;
		}

		li.show {
			display:block;
		}

		li.hide {
			display:none !important;
		}

		button.btn-fb {
			svg {
				position:relative;
				left:-1px;
			}
		}


		button.btn-play {
			svg {
				font-size: 14px;
				height: 14px;
				position: relative;
				left: 1px;
				top: -1px;

				@media only screen and (max-width: ${tablet}) {
					left: 1px;
					top: 1px;
				}
				
			}
		}

		button.btn-stop {

			svg {
				width: 12px;
				height: 12px;
				position: relative;
				top: -2px;
				left: 0;

				@media only screen and (max-width: ${tablet}) {
					top:2px;
					left:-1px;
				}
			}
		}

		button.btn-pause {
			svg {
				width: 14px;
				height: 14px;
				position: relative;
				left: 0px;
				top: -1px;

				@media only screen and (max-width: ${tablet}) {
					left: 0px;
    				top: 1px;
				}
				
			}
		}

	}

	button {
		border:1px solid #000;
		border-radius:30px;
		background:transparent;
		padding:9px;
		width:100px;
		font-size:16px;
		margin-right: 15px;
		outline:none;

		&:hover {
			background: #000;
			color:#F7B295;
			cursor:pointer;
			
			svg {
				fill:#F7B295 !important;
			}
		}

		@media only screen and (max-width: ${tablet}) {
			padding: 9px;
			width: 80px;
			font-size: 12px;
			margin-right: 10px;
		}
		
	}
`

const Divider = styled.hr`
	border:none;
	border-bottom:2px solid #000;
`

const MoreArtsts = styled.section`
	padding:40px;

	ul {
		margin:0;
		padding:0;
		column-count: 2;
		margin-top:40px;

		li {
			list-style:none;
			margin:0;
			padding:0;
			text-align:center;
			font-size:22px;
			display:block;
			margin-bottom:5px;

			@media only screen and (max-width: ${tablet}) {
				font-size:18px;
			}

			a {
				color:#000;
				text-decoration:none;

				&:hover {
					text-decoration:underline;
				}
			}
		}

		li.active {
			a{ 
				text-decoration:underline;
			}
		}
	}
`